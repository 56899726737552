<template>
  <tr>
    <!-- <td v-if="config.config.fieldsSelected">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions> -->
    <td class="text-xs-center" style="min-width: 70px; cursor: pointer;" @click="goToRouteDetail(talk.id)">
      <span>Protocolo:</span><br>
      <strong>#{{ talk.protocol }}</strong>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px; cursor: pointer;" @click="goToRouteDetail(talk.id)">
      <strong v-if="talk.status == 3" style="color: green; display: flex; flex-direction: column; align-items: center; font-size: 12px;">
        <v-icon color="green">done</v-icon>
        <span>Concluído</span>
      </strong>
      <strong v-if="talk.status == 1" style="display: flex; flex-direction: column; align-items: center; font-size: 12px;">
        <v-icon color="blue">smart_toy</v-icon>
        <span class="blue--text">Chatbot</span>
      </strong>
      <strong v-if="talk.status == 2" style="color: orange; display: flex; flex-direction: column; align-items: center; font-size: 12px;">
        <v-icon color="orange">play_arrow</v-icon>
        <span>Em Atendimento</span>
      </strong>
      <strong v-if="talk.status == 4" style="color: red; display: flex; flex-direction: column; align-items: center; font-size: 12px;">
        <v-icon color="red">close</v-icon>
        <span>Cancelado</span>
      </strong>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; cursor: pointer;" @click="goToRouteDetail(talk.id)">
      <span style="display: flex; justify-content: left; align-items: center; gap: 5px;">
        <span>
          <v-avatar v-if="talk.contact.file && talk.contact.file.url" size="40">
            <img :src="talk.contact.file.url" alt="">
          </v-avatar>
          <v-avatar v-else size="40">
            <group-icon v-if="talk.contact.type == 2" />
            <img v-else src="static/icons/user_icon_1.png" alt="">
          </v-avatar>
        </span>
        <span>
          <strong
            v-if="talk.contact && talk.contact.name"
            v-text="talk.contact.name"
          ></strong>
          <span v-else style="padding-left: 5px;">Não definido</span>
          <br>
          <span>{{ talk.contact.number }}</span>
          <v-tooltip v-if="talk.contact.checked == 2" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="green" small>
                verified
              </v-icon>
            </template>
            <span>
              Contato validado
            </span>
          </v-tooltip>
          <v-tooltip v-if="talk.contact.checked == 3" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="red" small>
                do_not_disturb_on
              </v-icon>
            </template>
            <span>
              Contato inválido
            </span>
          </v-tooltip>
        </span>
      </span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; cursor: pointer;">
      <span v-if="talk.operator && talk.operator.name" v-text="talk.operator.name" @click="goToRouteDetail(talk.id)"></span>
      <span v-else @click="goToRouteDetail(talk.id)">Não definido</span>
      <span v-if="talk.campaign && talk.campaign.name">
        <br>
        <label
          style="color: white; border: solid 1px grey; padding: 2px 5px; border-radius: 5px; cursor: pointer;"
          :style="{ backgroundColor: talk.campaign.status.color }"
          @click="$router.push({
            name: 'talk-list',
            params: { page: 'talk-campaign' },
            query: { where: `id,${talk.campaign.id}` }
          })"
        >
          <v-icon small color="white">{{ talk.campaign.status.icon }}</v-icon>
          <span v-text="talk.campaign.name" class="pl-1"></span>
        </label>
      </span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <v-chip
        style="cursor: pointer;"
        large :color="talk.channel.color"
        @click="$router.push({
          name: 'talk-list',
          params: { page: 'talk-channel' },
          query: { where: `id,${talk.channel.id}` }
        })"
      >
        <v-icon :color="$WiColorContrast(talk.channel.color)">{{ talk.channel.status.icon }}</v-icon>
        <strong
          v-text="talk.channel.name"
          class="ml-2" style="cursor: pointer;"
          :style="{color: $WiColorContrast(talk.channel.color)}"
        ></strong>
      </v-chip>
    </td>
    <!-- <td class="text-xs-left" style="padding: 0 10px !important; cursor: pointer;" @click="goToRouteDetail(talk.id)">
      <span v-text="talk.last_event_at"></span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px; cursor: pointer;" @click="goToRouteDetail(talk.id)">
      <span v-text="talk.time_total_formated"></span>
    </td> -->
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px; cursor: pointer;" @click="goToRouteDetail(talk.id)">
      <span>
        <v-icon small color="green">smart_display</v-icon>
        <span class="ml-1">
          {{ talk.opened_at | moment('DD/MM/YYYY HH:mm') }}
        </span>
      </span>
      <span v-if="talk.closed_at">
        <br>
        <v-icon small color="blue">stop_circle</v-icon>
        <span class="ml-1">
          {{ talk.closed_at | moment('DD/MM/YYYY HH:mm') }}
        </span>
      </span>
    </td>
    <!-- <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px; cursor: pointer;" @click="goToRouteDetail(talk.id)">
      <span v-text="talk.closed_at_formated"></span>
    </td> -->
  </tr>
</template>
<script>
  import GroupIcon from '@/modules/talk/components/icons/GroupIcon.vue';
  // import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TalksTable',
    computed: {
      talk () {
        return this.props.item
      }
    },
    methods: {
      openTicket: function (ticketId) {
        this.$router.push({name: 'ticket', params: {id: ticketId}})
      },
      goToRouteDetail: function (talkId) {
        this.$router.push({name: 'talk-view-messages', params: {id: talkId}})
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      GroupIcon
      // WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>